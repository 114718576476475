body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fafafa !important;
  font-family: "Roboto" !important;
}

code {
  font-family: "Roboto", source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  /* margin-bottom: 50em !important; */
}

/* a {
  color: black;
} */

/*
COLORS:
1. #00796b - teal darken-2
2. #00695c - teal darken-3
3. #00897b - teal darken-1
4. #cfd8dc - blue-grey lighten-4
5. #00bfa5 - teal accent-4
6. #009688 - teal
7. #00695c teal darken-3
8. #607d8b blue-grey
9. #546e7a blue-grey darken-1
*/
.no-margins {
  margin: 0 !important;
}
.float-right {
  float: right;
}

.center-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ui.secondary.nav-bar.menu {
  background-color: #00897b;
  margin-bottom: 1.7rem;
}
.ui.secondary.nav-bar.menu .item,
.ui.secondary.nav-bar.menu .active.item {
  color: white;
  font-size: 1.2rem;
  height: 100%;
  margin: 0;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
}

/* .ui .primary.button.action-button,
.ui .button.action-button,
.ui .button.confirm-button {
  background-color: #00796b;
  color: white;
}

.ui.primary.button.action-button:hover,
.labeled.button.action-button:hover,
.ui .button.confirm-button:hover {
  background-color: #00695c;
  color: white;
} */

a.ui.label.profile {
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
}
a.ui.label.verified.profile {
  background-color: #00796b;
  color: white;
}

a.ui.label.verified.profile:hover {
  background-color: #00695c;
}

a.ui.label.custom.profile {
  background-color: #607d8b;
  color: white;
}

a.ui.label.custom.profile:hover {
  background-color: #546e7a;
}

.ui .segment.inactive {
  background-color: #cfd8dc;
}

.inactive {
  background-color: #cfd8dc;
}

.confirm-button {
  background-color: #00bfa5;
}

.ui.message.no-resource {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.ui.no-resource .ui.header .icon {
  margin-right: 2rem;
}

.ui.modal div.content {
  padding-top: 0;
  padding-left: 2rem;
  padding-right: 2rem;
}

.ui.list > .item > .content.note-item-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item > .content.note-item-content > button.note-delete-button:hover {
  background-color: #db2828;
  /* text-shadow: none; */
}
button.note-delete-button:hover > i {
  color: white;
}

/* .ui.modal.profile-view {
  width: 50% !important;
} */

.ui.modal.profile-view > .ui.card {
  padding-top: 2rem;
  width: 100%;
}

.ui.modal.profile-view > .ui.card > .extra.content {
  padding-top: 1rem;
}

button.ui.button.FABMenu {
  background-color: #00897b;
}
button.ui.button.FABMenu > i.icon {
  font-size: 100%;
  margin: 0 auto !important;
}

/**Darken teal (#00897b)**/
button.ui.button.FABMenu:hover {
  background-color: #00695c;
}

button.ui.button.complete-maintenance:hover {
  background-color: #00695c;
  color: white;
}

/** All button theme colors**/
button.ui.button,
a.ui.button {
  background-color: #00897b !important;
  color: white !important;
}
button.ui.button:hover,
a.ui.button:hover {
  background-color: #00695c !important;
  color: white !important;
}

button.ui.button.note-delete-button {
  background-color: #d32f2f;
  /* text-shadow: none; */
}
button.ui.icon.button.note-delete-button:hover {
  background-color: #b71c1c;
  color: white;
  /* text-shadow: none; */
}

button.ui.button.negative {
  background-color: #d32f2f !important;
}

button.ui.button.negative:hover {
  background-color: #b71c1c !important;
  color: white;
}
button.ui.button.edit {
  background-color: #9e9e9e !important;
}
button.ui.button.edit:hover {
  background-color: #616161 !important;
}

@media only screen and (max-width: 767px) {
}
